@import "variable", "layout", "component", "page";
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

$theme-colors: (
  "primary": $color-primary,
  "success": $color-success,
  "warning": $color-warning,
  "danger": $color-danger,
  "info": $color-info,
  "light": $color-light,
  "secondary": $color-secondary,
  "dark": $color-dark
);

$enable-rounded: true;

* {
  font-family: $font-primary;
}

@import "../../../node_modules/bootstrap/scss/bootstrap";
@import "../../../node_modules/bootstrap/scss/variables";
@import "../../../node_modules/bootstrap/scss/mixins";
@import "../../../node_modules/bootstrap/scss/root";
