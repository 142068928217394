$global-padding: 20px;
$global-padding-tablet: 10px;
$sidebar-width: 300px;
$topbar-transition: all 0.3s ease-in-out;

// ========== WRAPPER ==========
body {
  background: $color-light !important;
}

// ========== WRAPPER ==========
.wrapper {
  width: 100vw;
  min-height: 100vh;
  background: $color-light;
}

// ========== BACKDROP ==========
.backdrop {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: black;
  opacity: 0.3;
  z-index: 9000;
  transition: $topbar-transition;
}

// ========== TOPBAR ==========
.topbar {
  height: 65px;
  width: 100vw;
  position: sticky;
  top: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: $global-padding;
  padding-right: $global-padding;
  z-index: 2000;
  background: $color-light;

  .title {
    display: flex;
    justify-content: start;
    align-items: center;
    cursor: pointer;

    .toggle {
      height: 35px;
      width: 35px;
      padding: 5px;
      color: $color-primary;
      cursor: pointer;
      opacity: 0.8;
      transition: $topbar-transition;

      &:hover {
        background: $color-light;
        border-radius: 100%;
        opacity: 1;
        transition: $topbar-transition;
      }
    }

    .text {
      text-transform: uppercase;
      margin-left: 5px;
    }
  }

  .account {
    display: flex;
    align-items: center;
    opacity: 1;
    transition: $topbar-transition;

    &:hover {
      opacity: 1;
      transition: $topbar-transition;
    }

    .icon {
      height: 25px;
      width: 25px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: -5px;
      border-radius: 100%;
      font-size: 12px;
      background: $color-primary;
      color: white;
    }

    .account-name {
      display: inline;
    }

    .dropdown {
      background: none !important;
      border: none !important;
      box-shadow: none !important;
      outline: none !important;

      button {
        background: none !important;
        border: none !important;
        box-shadow: none !important;
        outline: none !important;
        font-size: 14px;
        color: black;
      }

      &.show {
        background: none !important;
        border: none !important;
        box-shadow: none !important;
        outline: none !important;
      }
    }
  }
}

// ========== SIDEBAR ==========
.sidebar {
  height: 100vh;
  width: $sidebar-width;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: fixed;
  margin-left: -$sidebar-width;
  background: white;
  z-index: 10000;
  transition: all 0.3s ease-in-out;

  &.open {
    margin-left: 0px;
  }

  .head {
    display: flex;
    flex-direction: column;
    align-items: center;

    .logo {
      margin-top: 30px;
      margin-bottom: 10px;
      height: 90px;
    }

    .title {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 15px;
      margin-bottom: 10px;
      font-size: 14px;
    }
  }

  .body {
    margin-top: 10px;
    padding-bottom: 40px;
    margin-left: 10px;
    margin-right: 10px;
    overflow-x: hidden;
    overflow-y: auto;

    .nav {
      width: 280px;
      padding: 6px;
      margin-top: 3px;
      padding-left: 10px;
      padding-right: 10px;
      opacity: 0.75;
      color: black;
      cursor: pointer;
      font-weight: 500;
      text-transform: uppercase;
      font-size: 14px !important;
      transition: $topbar-transition;

      &:hover {
        opacity: 1;
        background-color: $color-light;
        z-index: 5;
        color: black;
        transition: $topbar-transition;
      }

      &.active {
        background-color: $color-primary;
        opacity: 1;
        z-index: 5;
        color: white;
        transition: $topbar-transition;
      }
    }

    .dropdown-item {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 7px;
      padding-left: 10px;
      padding-right: 10px;
      opacity: 0.75;
      color: black;
      cursor: pointer;
      text-transform: uppercase;
      font-weight: 500;
      font-size: 14px !important;
      transition: $topbar-transition;

      &:hover {
        background-color: $color-light;
        opacity: 1;
        color: black;
        transition: $topbar-transition;
      }

      &.open {
        background: $color-light;
        opacity: 1;
        cursor: pointer;
        color: black;
        transition: $topbar-transition;

        .chevron {
          transform: rotate(90deg);
          transition: $topbar-transition;
        }
      }
    }
  }
}

// ========== CONTENT ==========
.content {
  margin-left: $global-padding;
  margin-right: $global-padding;
}

// ========== PAGE LAYOUT ==========
.page-layout {
  .breadcrumb-wrapper {
    display: flex !important;
  }
}

// ========== RESPONSIVE BREAKPOINTS ==========

// Tablet Breakpoints
@media (max-width: 768px) {
  .topbar {
    padding-left: $global-padding-tablet;
    padding-right: $global-padding-tablet;

    .account {
      .account-name {
        display: none;
      }
    }
  }

  .content {
    margin-left: $global-padding-tablet;
    margin-right: $global-padding-tablet;
  }
}

// Mobile Breakpoints
@media (max-width: 576px) {
  .page-layout {
    .breadcrumb-wrapper {
      display: none !important;
    }
  }
}
