// Color
$color-primary: #1e3a8a;
$color-success: #15803d;
$color-warning: #f59e0b;
$color-danger: #dc2626;
$color-info: #00abda;
$color-light: #f3f4f6;
$color-secondary: #6b7280;
$color-dark: #0f172a;

// Font
$font-primary: "Roboto", sans-serif;

// Shadow
$shadow-sm: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;

// Border
$border: 1px solid #dee2e6 !important;

// Pagination
$pagination-color: $color-primary;
$pagination-active-bg: $color-primary;

// Modal
$modal-lg: 1200px;
$zindex-modal-backdrop: 5000;
$zindex-modal: 5050;
